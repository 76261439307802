import React, {Component} from 'react';
import styled from "styled-components";
import StyledButton from "../../shared/StyledButton"
import { Row, Col, Container } from "reactstrap"
import BroadwayInbound2014 from "images/new-york/BroadwayInbound2014-black-01.jpg"

const ContainerStyled = styled(Container)`
  text-align: center;
`

const Box = styled.div`
  background: linear-gradient(to bottom, ${props => props.theme.colors.red}, ${props => props.theme.colors.yellow});
  padding: 3px;
  margin: 1em auto;

  .helper {
    background: ${props => props.theme.colors.white};
    height: 100%;
    padding: 1rem;
  }
`

class GroupsBlock extends Component {
    constructor(props) {
        super(props);

        this.state = {
            customerModalOpen: false,
        }
    }

    toggleCustomerModal = (e) => {
        e.preventDefault();

        this.setState({
            customerModalOpen: !this.state.customerModalOpen
        });
    };

    render() {
      return (
        <ContainerStyled>
          <Row>
            <Col xs={12} lg={4}>
              <Box>
                <div className="helper">
                  <h2>Groups</h2>
                  <p className="black font-italic text-uppercase">GROUPS OF 10+</p>
                  <StyledButton target="_blank" rel="noopener"
                                href="https://www.broadwayinbound.com/shows/back-to-the-future-the-musical">
                      Click here
                  </StyledButton>
                  <p className="mt-4 mb-0">
                      or call <a className="font-weight-bold font-italic link--black"
                                  href="tel:(866)302-0995">(866) 302-0995</a>
                  </p>
                  <div className="imageWrapper">
                      <img className="mt-4 mb-0" style={{width: "200px"}} src={BroadwayInbound2014}
                            alt="Broadway Inbound" title="Broadway Inbound"/>
                  </div>
                </div>
              </Box>
            </Col>
            <Col xs={12} lg={4}>
              <Box>
                <div className="helper">
                  <h3>DIGITAL LOTTO</h3>
                  <p>The digital lottery can be found at <a href="https://rush.telecharge.com" target="_blank" rel="noopener ">rush.telecharge.com</a> with entries opening at 12 AM one day before the performance. Winners will be drawn that same day at 10 AM and 3 PM. $45 each (inclusive of $5 service fee), limit 2 tickets per person.<br/><br/>
                  <StyledButton target="_blank" rel="noopener" href="https://rush.telecharge.com">Enter Now</StyledButton>
                  </p>
                </div>
              </Box>
            </Col>
            <Col xs={12} lg={4}>
              <Box>
                <div className="helper">
                  <h3>GENERAL RUSH</h3>
                  <p>A limited number of rush seats will be available the morning of each performance when the Winter Garden Theatre box office opens. $40 each, limit 2 tickets per person, subject to availability. The box office opens Monday through Saturday at 10 AM and Sunday at 12 PM.</p>
                </div>
              </Box>
            </Col>
          </Row>
        </ContainerStyled>
      );
    }
}

export default GroupsBlock
