import React from "react"
import Layout from "components/Layout/LayoutNewYork"
import PageHeader from "components/shared/PageHeader"
import TicketCalendar from "components/shared/TicketCalendar"
// import PerformanceBlock from "components/new-york/Tickets/PerformanceBlock"
import FaqBlock from "components/new-york/Tickets/FaqBlock"
import GroupsBlock from "components/new-york/Tickets/GroupsBlock";
import { Helmet } from "react-helmet"

const TicketsPage = () => (
	<Layout title="Tickets | New York | Back to the Future the Musical"
			description="The official place to buy tickets for Back to the Future The Musical. Now playing on Broadway at the Winter Garden Theatre in New York."
                booking="https://www.telecharge.com/Broadway/Back-to-the-Future-the-Musical/Overview?AID=BWY001390400&utm_source=show_site&utm_campaign=Back-to-the-Future-the-MusicalSS&utm_medium=web"
        >
			<Helmet>
            <script
              id="satisfiScript"
              src="https://chat.satis.fi/popup/embedder?popupId=17903"
            ></script>
          </Helmet>
		<PageHeader title="Tickets" city="new-york" />
		<TicketCalendar />
		<GroupsBlock/>
		<FaqBlock/>
	</Layout>
)

export default TicketsPage
