import React, {Component} from 'react';
import styled from "styled-components";
import {graphql, StaticQuery} from "gatsby";
import {Container, Row, Col} from "reactstrap"
import iconArrow from "../../../images/icons/btn-arrows.svg"
import { media } from "utils/Media"
import CustomerModal from "./CustomerModal";

const scrollToElement = require('scroll-to-element')

const Content = styled(Container)`
    position: relative;
    margin: 0 auto;
    text-align: center;
    padding: 0;
    
    @media ${media.md} {
        padding: 0 15px;  
    }
`

const CustomCollapse = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 auto;
    text-align: left;
    
    @media ${media.md} {
        padding: 0.25rem;
    }
    
    .customCollapse__wrapper {
        width: calc(100% - 40px);
    }
    
    .customCollapse--title {
        text-transform: none;
    }

    .customCollapse__item {
        width: 100%;
        background: linear-gradient(to bottom, ${props => props.theme.colors.red}, ${props => props.theme.colors.yellow});
        padding: 3px;
        margin: 0.25rem 0;
        
        @media ${media.md} {
            margin: 1rem 0;
        }
        
        .helper {
            background: ${props => props.theme.colors.white};
            padding: 1rem;
            position: relative;
        }

        &-trigger {
            cursor: pointer;
            width: 100%;
            position: relative;
        }

        &-wrapper {
            height: 0;
            visibility: hidden;
            overflow: hidden;
            position: absolute;
            left: 0;
            width: 100%;
            flex-wrap: wrap;
            transition: height .3s ease;
            background: ${props => props.theme.colors.black};
            color: ${props => props.theme.colors.white};
            display: flex;
            align-items: center;
            text-align: left;

            &-inner {
                margin: 0;
                padding: 1rem;
                p {
                  margin: 0;
                }
            }
        }

        .arrowDown {
            position: absolute;
            top: 50%;
            right: 10px;
            width: 24px;
            height: 24px;
            transform: translateY(-50%);
            background: url("${iconArrow}") no-repeat center center;
            background-size: 100%;
            transition: transform 0.2s linear ;
        }

        &.active {
            .customCollapse__item-wrapper {
                visibility: visible;
            }
            .arrowDown {
                transform: translateY(-50%) rotate(180deg);
            }
        }
    }
`

class FaqBlock extends Component {
    constructor(props) {
        super(props);

        this.state = {
            activeId: "",
            contentHeight: 40,
            isOpen: false
        }

        this.toggleForm = this.toggleForm.bind(this)
    }

    toggleForm() {
        this.setState({
            isOpen: !this.state.isOpen
        })
    }

    clickHandler = (e, id) => {
        if (this.state.activeId === id) {
            this.setState({
                activeId: "",
                contentHeight: 40
            });
        } else {
            const trigger = e.currentTarget;
            const content = trigger.nextSibling;
            const inner = content.children[0];
            const height = inner.offsetHeight;

            setTimeout(() => {
                scrollToElement(`#cast-${id}`, {
                    duration: 0,
                    offset: -90
                });
            }, 100)

            this.setState({
                activeId: id,
                contentHeight: height
            });
        }

    }

    render() {
        const faqBookings = this.props.data.allFaqBookingNewYorkJson.edges.map(({node}, i) => {
            return <FaqItem
                key={i}
                id={`faqBooking-${i}`}
                data={node}
                clickHandler={this.clickHandler}
                classNames={this.props.classNames}
                activeId={this.state.activeId}
                contentHeight={this.state.activeId === `faqBooking-${i}` ? this.state.contentHeight : 1}
                activeClass={this.state.activeId === `faqBooking-${i}` ? 'active' : ''}
            />
        })

        const faqShows = this.props.data.allFaqShowNewYorkJson.edges.map(({node}, i) => {
            return <FaqItem
                key={i}
                id={`faqShow-${i}`}
                data={node}
                clickHandler={this.clickHandler}
                classNames={this.props.classNames}
                activeId={this.state.activeId}
                contentHeight={this.state.activeId === `faqShow-${i}` ? this.state.contentHeight : 1}
                activeClass={this.state.activeId === `faqShow-${i}` ? 'active' : ''}
            />
        })

        return (
            <Content className="my-3 my-md-5">

                <h2 className="h1">FREQUENTLY ASKED QUESTIONS</h2>
                {/*<CustomCollapse>*/}
                {/*    <Row className="mx-auto w-100">*/}
                {/*        {faqBookings}*/}
                {/*    </Row>*/}
                {/*</CustomCollapse>*/}

                {/*<h3 className="h2 mt-3">QUESTIONS ABOUT THE SHOW</h3>*/}
                <CustomCollapse>
                    <Row className="mx-auto">
                        {faqShows}
                    </Row>
                </CustomCollapse>
                <CustomerModal modalOpen={this.state.isOpen} toggleModal={this.toggleForm}/>
            </Content>

        );
    }
}

export default (props) => (
    <StaticQuery
        query={graphql`
            query {
                allFaqBookingNewYorkJson {
                    edges {
                        node {
                            question
                            answerHTML
                        }
                    }
                }
                allFaqShowNewYorkJson {
                    edges {
                        node {
                            question
                            answerHTML
                        }
                    }
                }
            }
		`}
        render={data => (
            <FaqBlock data={data}/>
        )}
    />
)

class FaqItem extends Component {
    render() {
        return (
            <Col xs={12} lg={6}>
                <div
                    className={`customCollapse__item ${this.props.activeClass}`}>
                    <div className="helper" style={{paddingBottom: this.props.contentHeight + "px"}}>
                        <div
                            id={`cast-${this.props.id}`}
                            className="customCollapse__item-trigger"
                            onClick={(e) => this.props.clickHandler(e, this.props.id)}>
                            <div className="customCollapse__wrapper">
                                <div className="customCollapse__copy-wrapper">
                                    <h4 className="customCollapse--title">{this.props.data.question}</h4>
                                </div>
                            </div>
                            <div className="arrowDown"/>
                        </div>
                        <div
                            className="customCollapse__item-wrapper"
                            style={{height: this.props.contentHeight + "px"}}>
                            <div className="customCollapse__item-wrapper-inner">
                                <div className="narrow">
                                    <div className="customCollapse__item-content"
                                         dangerouslySetInnerHTML={{__html: this.props.data.answerHTML}}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Col>
        )
    }
}