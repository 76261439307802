import React, {Component} from 'react';
import {Modal, ModalBody} from "reactstrap"
import styled from "styled-components"
import {media} from "utils/Media"
import logo from "../../../images/BTTF-LOGO-small.png"
import CustomerServicesForm from "./CustomerServicesForm";

const VModal = styled(Modal)`
    max-width: 900px;
    padding: 0;
    border: 0;
    color: ${props => props.theme.colors.black};
    text-align: center;

    .modal-content {
        border: 0;
        position: relative;
        border-radius: 0;
        background-color: ${props => props.theme.colors.white};
    }

    .modal-body {
        padding: 0.5rem;
        border: 0;
        
        @media ${media.sm} {
            padding: 0;
        }
    }
    
    h2, h3 {
        text-align: center;
        color: ${props => props.theme.colors.black};   
    }
    
    h2 {
        @media ${media.xl} {
            font-size: 2.5rem;
        }
    }
    
    .logo {
        width: 100%;
        height: auto;
        max-width: 220px;
        position: absolute;
        top: -120px;
        left: 50%;
        transform: translateX(-50%);
    }
`

const VModalBody = styled(ModalBody)`
    padding: 1rem !important;
`

const Table = styled.div`
    width: 100%;
    max-width: 700px;
    margin: 1rem auto;   
`

const TableRow = styled.div`
    width: 100%;
    display: flex;
    
    .tableCol {
        width: 50%;
        padding: 0.25rem;
    }   
`

const ButtonClose = styled.button`
    position: absolute;
    background-color: transparent;
    z-index: 10;
    right: 5px;
    top: 5px;
    width: 30px;
    height: 30px;
    border: 0;
    
    @media (min-width: 992px) {
        width: 50px;
        height: 50px;
    }
    
    &:after,
    &:before {
        content: "";
        width: 3px;
        height: 60%;
        background-color: black;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) rotate(45deg);
    }

    &:after {
        transform: translate(-50%, -50%) rotate(-45deg);
    }

`

const Box = styled.div`
    width: calc(100% - 30px);
    background: linear-gradient(to bottom, ${props => props.theme.colors.red}, ${props => props.theme.colors.yellow});
    padding: 3px;
    margin: 3rem 15px 2rem;
    
    .helper {
        background: ${props => props.theme.colors.white};
        padding: 1rem;
        position: relative;
        color: ${props => props.theme.colors.black};
        
        h3 {
            color: ${props => props.theme.colors.black};
        }
       
    }
`

class CustomerModal extends Component {
    render() {
        return (
            <VModal isOpen={this.props.modalOpen} toggle={this.props.toggleModal} centered={true}>
                <ButtonClose onClick={this.props.toggleModal}>
                    <span className="sr-only">Close</span>
                </ButtonClose>

                <VModalBody>
                    <h2 className="h1">TALK TO ONE OF OUR TEAM</h2>
                    <Box>
                        <div className="helper">
                            <CustomerServicesForm />
                        </div>
                    </Box>
                </VModalBody>
            </VModal>
        );
    }
}

export default CustomerModal;